<template>
  <div v-html="legacyHTML"></div>
</template>

<script>
import routes from "../router/routes";

export default {
  name: "Sitemap",
  data() {
    return {
      routes: routes,
      exampleData: "Data from base component",
      legacyHTML: `<xml version="1.0" encoding="UTF-8">
            <urlset
                    xmlns:news="http://www.google.com/schemas/sitemap-news/0.9"
                    xmlns:xhtml="http://www.w3.org/1999/xhtml"
                    xmlns:image="http://www.google.com/schemas/sitemap-image/1.1"
                    xmlns:video="http://www.google.com/schemas/sitemap-video/1.1">
                <url>
                    <loc>https://example.com/</loc>
                </url>
                <url>
                    <loc>https://example.com/about</loc>
                </url>
            </urlset>
        </xml>`,
    };
  },
  created() {
    this.$emit("hide", true);
    console.table(this.routes);
  },
};
</script>

<style scoped></style>
